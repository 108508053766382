import { lighten, darken, rgba } from 'polished'

export const black = '#000'
export const white = '#fff'

// Site Specific Colors
export const yellow = 'yellow'
export const red = '#DC0000'
export const green = '#00ff00'
export const blue = '#020093'

// Basic Colors
export const cream = '#F0F0EE'
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = white
export const mainColor = green
export const alert = red
export const notify = yellow
export const success = green
export const textColor = black
export const lightTextColor = rgba(textColor, 0.4)
export const lightGrey = '#F2F2F2'
export const hrColor = rgba(textColor, 0.3)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
