import * as typography from './typography'
import * as mediaQueries from './mediaQueries'
import * as colors from './colors'
import * as animations from './animations'
import * as util from './util'
import * as fonts from './fonts'
import { slick } from './slick'
import { rgba } from 'polished'

const responsiveStyles = util.responsiveStyles

// All global styles
export default `
  ${ fonts.MaterialIconsFont }
  ${ fonts.PlainFont }
  ${ fonts.PlainFontBold }
  ${ fonts.HaasFont }
  ${ fonts.HaasFontBold }

  * {
    box-sizing: border-box;
  }

  :root {
    --text-color: ${ colors.textColor };
    --bg-color: ${ colors.bgColor };
  }

  html {
    font-display: block;
    background: var(--bg-color);
    color: var(--text-color);
    ${ util.fontSmoothing }
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    padding: 0;
    margin: 0;
    background-attachment: fixed;
    height: 100%;
    &.page-lock {
      position: relative;
      overflow: hidden;
      height: 100%;
    }
    color-scheme: light dark;
    @media (prefers-color-scheme: dark) {
      background: #000;
      color: ${ colors.bgColor };
    }
  }

  body {
    ${ typography.body }
    background: var(--bg-color);
    color: var(--text-color);
    padding: 0;
    margin: 0;
    cursor: -webkit-image-set(url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACOSURBVHgBpZLRDYAgEEOrEzgCozCCGzkCbKArOIlugJvgoRAUNcLRpvGH19TkgFQWkqIohhK8UEaKwKcsOg/+WR1vX+AlA74u6q4FqgCOSzwsGHCwbKliAF89Cv89tWmOT4VaVMoVbOBrdQUz+FrD6XItzh4LzYB1HFJ9yrEkZ4l+wvcid9pTssh4UKbPd+4vED2Nd54iAAAAAElFTkSuQmCC") 1x, url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEsSURBVHgBxVfRFYIwDLwyAW6AE8gGOIKjuIEjwAbqBo4gG+AEZQPYoCZQHj7lKZXSXN7xAQ/uSEpIFebCIKbjgZgRU2JCjO3VllgTK2JJvEF15zzAkJBBTmyILnHu7l0gHFvhpZHb7Dm/tfYgPoSenw1D9fUr/moiDf3mDpnoa65XEn43EU8Z8LHg5kY+lfrQkbB0ZC2cEB5HPihbjwbhwZ1yyxk4QAZda2cDGeSQsYEUckh5DXD9HXu1N7Sq+yAEEUEYbMDT4PAXWjZQQw41G3hADhUbuEMO5dCKNWQ+xU1kp9crwuMyTs6Cv+MRBsVKQlNRfCZEfCQbS6FXEh7EE3yF6Fi+XiY0nLdp/ZrwsTALmCU9ps/GxVG0scLJr8crzMW4Pd8Td/C0PX8C0nMJzs0h22AAAAAASUVORK5CYII=") 2x) 8 8, auto;
    a {
      &:hover {
        cursor: -webkit-image-set(url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACLSURBVHgBpZJhDYAgFIRPLWAEIhiBhtBAK5hEG2AFE+jDPTZEp/D4ts/5g7udDuCJJg3pyINdyJFU+KDn4PGj4bOP8JIRjhfdSkxBOF5yoQThoO64aYCMHSj79lTX8IuYFpX4gg1yVl8wQ87qHxryn6hCkxWEbTyl+iqHkpwl9i0co8gpWeQ4qNPDJzAlgKF4SudWAAAAAElFTkSuQmCC") 1x, url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEiSURBVHgBxZfRzcIwDISv/AtkBG/wd4MyApvRDYANeOQRNoAJ6AZ0g2KXRASE1ATc+JNOqiq1d0mc1K2QjmOtWA2rZpG/J/SsjnVmnVh7f08FYq1ZN9aQoY1/9mucNx5+1BrPmUqGWFcF86ArMmajVjaPQ9RT5jSTedJMuJnN4xAfa0Kj4HIK8wUqaB4knviLRj9ZIMpUrINcOJQfvUgONrfA43i1YDzaJUADOxoJUHrtY2ophHEtYEMvAQYYsoAxEkCtcfiCXgJ0sKOTABfYcZYAR9gh/eO4BXP7PS25UIQ7lGeLaAMQyo+e3hO1Bc1bfMC8JQOMm9KAaVs+10wkjfwdWSeNwmzx4+ee8NizOaY3b0xTL6+QTvg9X7L+ofR7fgdg87qCkqLYzwAAAABJRU5ErkJggg==") 2x) 8 8, auto !important;
      }
    }
    color-scheme: light dark;
    @media (prefers-color-scheme: dark) {
      background: #000;
      color: ${ colors.bgColor };
    }
  }

  @media (prefers-color-scheme: dark) {
    --text-color: ${ colors.bgColor };
    --bg-color: ${ colors.textColor };
  }

  b, strong {
    font-weight: 600;
  }

  em, i {
    font-style: italic;
  }

  h1, h2, h3, h4, h5, h6, blockquote, p, ul, ol {
    font-weight: normal;
    margin: 0 0 0.5em;
  }

  h1, h2, h3, h4, h5, h6, blockquote {
    b, strong {
      font-weight: 600;
    }
  }

  p {
    ${ typography.body }
    margin-top: 1em;
    margin-bottom: 1.5em;
    &.small {
      ${ typography.bodySmall }
    }
    &.medium {
      ${ typography.bodyMedium }
    }
    &.large {
      ${ typography.bodyLarge }
    }
    em, b, strong {
      font-style: normal;
      font-family: ${ typography.secondaryFont };
      font-weight: ${ typography.bold };
    }
    a {
      border-bottom: 1px dashed currentcolor;
      padding-bottom: 0em;
      &:hover {
        border-color: currentcolor;
        border-bottom: 1px solid currentcolor;
      }
    }
  }

  h1, .h1 {
    ${ typography.h1 }
    ${ responsiveStyles('margin-top', 10, 10, 10, 8) }
    ${ responsiveStyles('margin-bottom', 12, 10, 8, 8) }
  }

  h2, .h2 {
    ${ typography.h2 }
    ${ responsiveStyles('margin-top', 10, 10, 10, 8) }
    ${ responsiveStyles('margin-bottom', 10, 10, 8, 8) }
  }

  h3, .h3 {
    ${ typography.h3 }
    ${ responsiveStyles('margin-top', 10, 10, 10, 8) }
    ${ responsiveStyles('margin-bottom', 14, 12, 12, 10) }
  }

  h4, .h4 {
    ${ typography.h4 }
    ${ responsiveStyles('margin-top', 24, 16, 16, 8) }
    ${ responsiveStyles('margin-bottom', 14, 12, 12, 10) }
  }

  h5, .h5 {
    ${ typography.h5 }
    ${ responsiveStyles('margin-top', 24, 16, 16, 8) }
    margin-bottom: 0;
  }

  h6, .h6 {
    ${ typography.h6 }
    margin-top: 0;
    ${ responsiveStyles('margin-bottom', 24, 16, 16, 8) }
  }

  figcaption {
    ${ typography.bodySmall }
    color: ${ colors.lightTextColor };
  }

  hr {
    margin: 2em auto;
    border: 0;
    display: block;
    border-bottom: 1px solid ${ colors.hrColor };
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition:   color ${ animations.mediumSpeed } ease-in-out,
                  border ${ animations.mediumSpeed } ease-in-out,
                  background ${ animations.mediumSpeed } ease-in-out,
                  opacity ${ animations.mediumSpeed } ease-in-out,
                  transform ${ animations.mediumSpeed } ease-in-out;
    &.text-link {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      text-decoration: none;
      letter-spacing: 1.75px;
      border-bottom: 1px solid ${ colors.mainColor };
      text-transform: uppercase;
      color: ${ colors.mainColor };
      padding-bottom: 3px;
    }
  }

  // Remove grey rectangle from iOS taps
  a, input, button {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  blockquote {
    ${ typography.blockquote };
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  time {
    ${ typography.bodySmall };
  }

  ::selection {
    background: ${ rgba(colors.mainColor, 0.9) };
    color: ${ colors.color };
  }
  
  ${ slick }

`
