import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import PlainWoff from '../assets/fonts/plain/plain-regular.woff'
import PlainWoff2 from '../assets/fonts/plain/plain-regular.woff2'

import PlainBoldWoff from '../assets/fonts/plain/plain-bold.woff'
import PlainBoldWoff2 from '../assets/fonts/plain/plain-bold.woff2'

import HaasWoff from '../assets/fonts/haas/NHaasGroteskTXPro-55Rg.woff'
import HaasWoff2 from '../assets/fonts/haas/NHaasGroteskTXPro-55Rg.woff2'

import HaasBoldWoff from '../assets/fonts/haas/NHaasGroteskTXPro-65Md.woff'
import HaasBoldWoff2 from '../assets/fonts/haas/NHaasGroteskTXPro-65Md.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const Plain = 'Plain'
export const PlainFont = fontFace(Plain, PlainWoff, PlainWoff2)
export const PlainFontBold = fontFace(Plain, PlainBoldWoff, PlainBoldWoff2, 700)

export const Haas = 'Haas'
export const HaasFont = fontFace(Haas, HaasWoff, HaasWoff2)
export const HaasFontBold = fontFace(Haas, HaasBoldWoff, HaasBoldWoff2, 700)
